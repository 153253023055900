import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { FormHelperText, Stack, Tooltip } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
// translation
import { useLocales } from '../../locales';
import Iconify from '../iconify';
export default function RHFDate({ name, inputFormat, helperText, multiline, rows, type, inputRef, id, label, isDisabled, views, ...other }) {
    const { control, setValue } = useFormContext();
    const { translate } = useLocales();
    const today = new Date();
    today.setHours(0, 0, 0);
    return (_jsx(Controller, { name: name, control: control, render: ({ field, fieldState: { error } }) => (_jsxs(Stack, { sx: { display: 'flex', flexDirection: 'column' }, children: [_jsxs(Stack, { sx: {
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '10px',
                        width: '100%',
                    }, children: [_jsx(DatePicker, { views: views, label: `${translate(label)} `, disabled: isDisabled, value: field.value ? new Date(field?.value) : null, onOpen: () => {
                                setValue(name, field.value ? new Date(field?.value) : new Date(today));
                            }, sx: {
                                width: '100%',
                                '& .MuiInputLabel-root': {
                                    color: typeof error?.message === 'string' ? '#FF5630' : undefined,
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: typeof error?.message === 'string' ? '#FF5630' : undefined,
                                    },
                                    '&:hover fieldset': {
                                        borderColor: typeof error?.message === 'string' ? '#FF5630' : undefined,
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'black',
                                    },
                                    '& .MuiInputLabel-root.Mui-focused': {
                                        color: 'black',
                                    },
                                },
                            }, onChange: (newValue) => {
                                field.onChange(newValue);
                            }, format: inputFormat || 'dd/MM/yyyy' }), _jsx(Tooltip, { title: `${translate(helperText)}` || `${translate(label)}`, placement: "right-start", children: _jsx(Iconify, { icon: "ri:information-line", width: 23, sx: { cursor: 'help', color: '#9AA6B2' } }) })] }), (!!error || helperText) && (_jsx(FormHelperText, { error: !!error, children: error ? `${translate(error?.message)}` : `${translate(helperText)}` }))] })) }));
}
